import React from "react"
import { Link } from "gatsby"
import { Button, Media, Row, Col } from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import CommonFlex from '../components/commonFlex'
import Footer from '../components/footer'
import { bdstatistics, apiURL, mqStatistics } from '../components/census'
// import Vanta from '../components/Vanta'

import '../components/animate-index.css'

const data = [
  {
    title: '一招模型化，快捷管理资产',
    title_desc: '大幅缩短设备的配置周期',
    img: require('./../images/product22.png'),
    tag_data: `<div>
      <h5 class="product-title">一招模型化，快捷管理资产</h5>
      <p class="product-desc">大幅缩短设备的配置周期</p>
      <div class="product-secord-box">
        <div class="product-secord-title">模型统一配置，海量资产轻松复用</div>
        <div class="product-desc product-font">通用属性只需要通过模型配置即可，节省人力成本，减少无效重复操作</div>
      </div> 
      <div class="product-secord-box">
        <div class="product-secord-title">类型化管理设备</div>
        <div class="product-desc product-font">支持自定义资产类型，通过类型可统一管理多个模型 下的资产信息</div>
      </div> 
      <div class="product-secord-box">  
        <div class="product-secord-title">灵活自定义属性</div>
        <div class="product-desc product-font">支持自定义属性信息，无需代码信息</div>
      </div>
    </div>`,
    name: 'model'
  },
  {
    title: '驱动能力夯实，自由采集数据',
    title_desc: '大大满足任意数据采集需求',
    img: require('./../images/product1.png'),
    tag_data: `<div>
      <h5 class="product-title">驱动能力夯实，自由采集数据</h5>
      <p class="product-desc">大大满足任意数据采集需求</p>
      <div class="product-secord-box">
        <div class="product-secord-title">驱动类型丰富</div>
        <div class="product-desc product-font">驱动包括但不限于通用驱动信息，无线驱动信息， 行业驱动信息及各个厂商驱动；</div>
      </div> 
      <div class="product-secord-box">
        <div class="product-secord-title">驱动稳定性强</div>
        <div class="product-desc product-font">经受住各个大型项目的考验，持续稳定运行</div>
      </div> 
      <div class="product-secord-box">  
        <div class="product-secord-title">支持分布式部署</div>
        <div class="product-desc product-font">数据采集能力强，解决海量数据采集一台服务器采集困难的问题，同时稳定性强，应对故障灵活性强</div>
      </div>
    </div>`,
    bg_data: `<div class="produce-none" style="
      position: absolute;
      top: 0px;
      left: -120px;
      z-index: 88;
      border-top: 300px solid transparent;
      border-bottom: 300px solid transparent;
      border-left: 700px solid #F3F3FF;
      border-radius: 10px;
    "></div>`,
    name: 'driver'
  },
  {
    title: '画面编辑，低代码绘制，强支持扩展',
    title_desc: '强大与灵活并存的同时保证了操作简易',
    img: require('./../images/product8.jpeg'),
    tag_data: `<div>
    <h5 class="product-title">画面编辑，低代码绘制，强支持扩展</h5>
    <p class="product-desc">强大与灵活并存的同时保证了操作简易</p>
    <div class="product-secord-box">
      <div class="product-secord-title">组件库丰富</div>
      <div class="product-desc product-font">内置丰富类型的组件库，支持各种功能性组件，可轻松搭建任意复杂的流程图</div>
    </div> 
    <div class="product-secord-box">
      <div class="product-secord-title">支持二次开发</div>
      <div class="product-desc product-font">新需求满足成本低，可自定义二次开发，提供全面的接口信息</div>
    </div> 
    <div class="product-secord-box">  
      <div class="product-secord-title">美化的视觉效果</div>
      <div class="product-desc product-font">通过主题、组件及属性，满足各中认知中的美观视觉效果</div>
    </div>
  </div>`,
  // bg_data: `<div class="produce-none" style="
  //   position: absolute;
  //   top: 210px;
  //   // right: -120px;
  //   z-index: 88;
  //   border-top: 274px solid transparent;
  //   border-bottom: 274px solid transparent;
  //   border-left: 274px solid #EEF9FA;
  //   border-radius: 10px;
  //   transform: rotate(45deg);
  //   "></div>`,
  name: 'dashboard'
  },
  {
    title: '智能报表，表格化定义',
    title_desc: '可视编辑报表，灵活定义表格',
    img: require('./../images/product4.png'),
    tag_data: `<div>
    <h5 class="product-title">智能报表，表格化定义</h5>
    <p class="product-desc">可视编辑报表，灵活定义表格</p>
    <div class="product-secord-box">
      <div class="product-secord-title">表格式可视定义</div>
      <div class="product-desc product-font">报表定义时以表格形式展示，用户可以可视查看报表定义出表头的信息及样式，操作简单，功能强大</div>
    </div> 
    <div class="product-secord-box">
      <div class="product-secord-title">支持多种数据类型</div>
      <div class="product-desc product-font">报表单元格支持文本输入、数据绑定、斜线表头等，可以按照需要进行编辑，且数据信息当外部选中模型后可以直接点选</div>
    </div> 
    <div class="product-secord-box">  
      <div class="product-secord-title">样式自定义</div>
      <div class="product-desc product-font">单元格的任意样式用户可以自定义</div>
    </div>
  </div>`,
  bg_data: `<div class="produce-none" style="
    position: absolute;
    top: 50px;
    left: -120px;
    width: 243px;
    height: 485px;
    background-color: #F3F3FF;
    border-radius: 0px 485px 485px 0px;
    z-index: 88;
  "></div>`,
  name: 'report'
  },
  {
    title: '智能报警，灵活定义，多方式提醒',
    title_desc: '复杂报警自定义，支持语音、邮件、钉钉、微信等多种提醒方式',
    img: require('./../images/product6.png'),
    tag_data: `<div>
    <h5 class="product-title">智能报警，记录数据全流程，提醒方式不受限</h5>
    <p class="product-desc">复杂报警自定义，支持语音、邮件、钉钉、微信等多种提醒方式</p>
    <div class="product-secord-box">
      <div class="product-secord-title">报警配置简单且全面</div>
      <div class="product-desc product-font">复杂报警配置通过下拉列表形式递进式选择即可，可定义属性包括延时时间、提醒、报警死区等，更好的满足各种报警需求</div>
    </div> 
    <div class="product-secord-box">
      <div class="product-secord-title">报警分析</div>
      <div class="product-desc product-font">产生的报警信息按照级别及类型进行统计，支持按照时间、部门、报警类型等对报警进行统计分析</div>
    </div> 
    <div class="product-secord-box">  
      <div class="product-secord-title">智能报警</div>
      <div class="product-desc product-font">定义的报警及预报警发生，支持通过语音播报、微信、邮箱、钉钉等方式提醒，并可与视频的联动，增强报警的及时性，降低损失</div>
    </div>
  </div>`,
  name: 'warning'
  },
  {
    title: '工作表，自定义数据表',
    title_desc: '快速按需定义数据表，自定义或一键同步数据库表信息',
    img: require('./../images/product2.png'),
    tag_data: `<div>
    <h5 class="product-title">工作表，自定义数据表</h5>
    <p class="product-desc">快速按需定义数据表，自定义或一键同步数据库表信息</p>
    <div class="product-secord-box">
      <div class="product-secord-title">可链接数据库</div>
      <div class="product-desc product-font">通过配置链接外部数据库，配置信息填写完整后可检测数据库链接状态，链接失败时会提示用户失败原因</div>
    </div> 
    <div class="product-secord-box">
      <div class="product-secord-title">数据字段控件类型多</div>
      <div class="product-desc product-font">自定义数据的控件类型包括文本、布尔值、选择器、时间、数值、附件、附件组及关联字段等，其中关联字段可关联内部表及其他工作表</div>
    </div> 
    <div class="product-secord-box">  
      <div class="product-secord-title">工作表视图</div>
      <div class="product-desc product-font">对于已定义好的数据表，添加记录后形成的记录表，可按需定义其查看时、编辑时、添加时的视图</div>
    </div>
  </div>`,
  bg_data: `<div class="produce-none" style="
    position: absolute;
    top: 0px;
    left: -120px;
    z-index: 88;
    border-top: 300px solid transparent;
    border-bottom: 300px solid transparent;
    border-left: 700px solid #F3F3FF;
    border-radius: 10px;
  "></div>`,
  name: 'table'
  },
  {
    title: '拥抱瞬变的需求，支持模块二次开发',
    title_desc: '提供接口，支持定制化扩展模块功能',
    img: require('./../images/product7.png'),
    tag_data: `<div>
    <h5 class="product-title">拥抱瞬变的需求，支持模块二次开发</h5>
    <p class="product-desc">提供接口，支持定制化扩展模块功能</p>
    <div class="product-secord-box">
      <div class="product-secord-title">提供功能扩展接口及完备的接口文档</div>
      <div class="product-desc product-font">当项目对平台中的功能模块有定制化的需求时，支持针对模块的二次开发，提供完善的接口及完备的指导操作的接口文档</div>
    </div> 
    <div class="product-secord-box">
      <div class="product-secord-title">开发语言包容性强</div>
      <div class="product-desc product-font">对于开发语言，支持语言类型众多，包括：go语言、Java语言、node.js语言及python语言，自由选取开发</div>
    </div> 
    <div class="product-secord-box">  
      <div class="product-secord-title">发布流程简单</div>
      <div class="product-desc product-font">对于用户自定义的扩展模块，具有明确的发布应用流程线，操作简单</div>
    </div>
    </div>`,
  // bg_data: `<div class="produce-none" style="
  //   position: absolute;
  //   top: 210px;
  //   // right: -120px;
  //   z-index: 88;
  //   border-top: 274px solid transparent;
  //   border-bottom: 274px solid transparent;
  //   border-left: 274px solid #EEF9FA;
  //   border-radius: 10px;
  //   transform: rotate(45deg);
  //   "></div>`,
  name: 'api'
  },
  {
    title: '保障安全，审计领航',
    title_desc: '审计功能，保证有迹可循，有据可依',
    img: require('./../images/product3.png'),
    tag_data: `<div>
    <h5 class="product-title">保障安全，审计领航</h5>
    <p class="product-desc">审计功能，保证有迹可循，有据可依</p>
    <div class="product-secord-box">
      <div class="product-secord-title">全方位操作进行审计</div>
      <div class="product-desc product-font">审计的内容包括平台中的任意操作，各个功能模块的各个类型均分类进行审计管理，方便查找和追踪</div>
    </div> 
    <div class="product-secord-box">
      <div class="product-secord-title">审计内容详细记录</div>
      <div class="product-desc product-font">对于一条审计记录，操作内容详细记录，并且对于操作不同类型标注不同的颜色，清晰明了</div>
    </div> 
    <div class="product-secord-box">  
      <div class="product-secord-title">支持对编辑器内组件操作得审计记录</div>
      <div class="product-desc product-font">支持对编辑器内组件操作得审计记录</div>
    </div>
    </div>`,
    name: 'log'
  }
]

const ProductPage = () => {
  let [ hash, setHash ] = React.useState(null)

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
  }, [])

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      let windowHeight = document.documentElement.clientHeight

      window.addEventListener('scroll', (event) => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let animate_box = document.getElementsByClassName('product-media')

        let box = document.getElementsByClassName('img-bg-edit')
        let animate_box_media = document.getElementsByClassName('product-media')

        for(let i=0; i<animate_box.length;i++) {
          if(animate_box[i].offsetTop < (windowHeight + scrollTop + 120)) {
            if(i%2 === 0) {
              box[i].style.animation = `slide-bck-center 4s ease-in-out 0s 1  forwards`
            }else {
              box[i].style.animation = `slide-bck-center1 4s ease-in-out 0s 1  forwards`
            }
          }else {
            if(i%2 === 0) {
              box[i].style.animation = `slide-bck-center2 4s ease-in-out 0s 1  forwards`
            }else {
              box[i].style.animation = `slide-bck-center3 4s ease-in-out 0s 1  forwards`
            }
          }
        }
 
        // for(let i=0; i<animate_box.length;i++) {
        //   if(animate_box[i].offsetTop < (windowHeight + scrollTop)) {
        //     animate_box_media[i].style.animation = `fadeInUp 1s linear`
        //   }else {
        //     animate_box_media[i].style.animation = `fadeOut 1s linear`
        //   }
        // }

      })
      
      if(window.location.hash && window.location.hash.indexOf('#') >-1)  {
        setHash(window.location.hash.substr(window.location.hash.indexOf('#')+1))
      }
    }
  },  [])

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      // 跳转到指定位置
      let product_media = document.getElementsByClassName('product-media');
      if(hash) {
        for(let i=0; i<product_media.length;i++) {
          let name = product_media[i].getAttribute('name')
          if(name == hash) {
            window.scrollTo({
              top: product_media[i].offsetTop -150
            })
          }
        }
      }
    }
  }, [hash])

  const toHerf = () => {
    if(typeof window !== "undefined") {
      setHash('model')
      window.scrollTo({
        top: 1000
      })
    }
  }

  return (
    <div className="product-page">
      <div className="home-bg" style={{background:`url(${require("./../images/product.png")}) no-repeat center top`, backgroundSize: 'cover' }} >
        <Layout>
          <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
          <div>
            <div className="plat-jumbotron" style={{ textAlign: 'center', margin: '0 auto', paddingTop: '13.125rem' }}>
              <div className="plat-title">丰富的应用库高效解决物联难题</div>
              <div className="plat-desc" style={{ margin: '0.75rem auto 2rem', maxWidth: '50rem' }}>
              可快速搭建自己的物联网平台，通过模型化管理设备，数据处理能力强，创建数据展示表，如参数汇总、报表，快速创建流程图，具有低成本、易操作，高效率、高可靠的特点
              </div>
              <p><Button variant="primary" className="plat-start" style={{ fontSize: '1.25rem' }} onClick={() => { toHerf() }}>查看更多</Button></p>
            </div>
          </div>
        </Layout>
      </div>
      {/* <Vanta></Vanta> */}
      <Container className="content-box product-container">
        <CommonFlex title="面向市场 高度契合" desc=""></CommonFlex>
        <>
          {
            data.map((item, index) => {
              if(index%2 === 0) {
                return (
                  <div>
                    {
                      item.bg_data ? <div style={{ position: 'relative', zIndex: 8 }} dangerouslySetInnerHTML={{__html: item.bg_data }}></div> : null
                    }
                    <Media className="product-media row" name={item.name} style={ item.bg_data ? { position: 'relative', zIndex: 99} : null }>
                      <Col sm={7} style={{ margin: 'auto 0', zIndex: 9 }}>
                        <div className={`box-animation box-${index}`} style={{ width: '96%', height: '100%'}}>
                          {/* <div className="img-bg-edit" style={{ backgroundImage:`url(${require("./../images/left-bg-edit.jpg")})` }}> */}
                            <img className="img-bg-edit"  src={item.img} style={{ width: '100%', height: '100%', marginBottom: '2rem' }} alt="Generic placeholder" />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col sm={5}  className="product-sm-animate" style={{ padding: '0 0 0 54px',  zIndex: 9}}>
                        <Media.Body>
                          <div className="product-pos">
                            <div className="product-pos" dangerouslySetInnerHTML={{__html: item.tag_data }}></div>
                          </div>
                        </Media.Body>
                      </Col>
                    </Media>
                  </div>
                )
              }else {
                return (
                  <div> 
                    {
                      item.bg_data ? <div style={{ position: 'relative', zIndex: 8 }} dangerouslySetInnerHTML={{__html: item.bg_data }}></div> : null
                    }           
                    <Media className="product-media row" name={item.name} style={ item.bg_data ? { position: 'relative', zIndex: 99} : null }>
                      <Col sm={5} className="product-sm-animate" style={{ zIndex: 9}}>
                        <Media.Body>
                          <div className="product-pos">
                            <div className="product-pos" dangerouslySetInnerHTML={{__html: item.tag_data }}></div>
                          </div>
                        </Media.Body>
                      </Col>
                      <Col sm={7} style={{ margin: 'auto 0', zIndex: 9 }}>
                        <div className={`box-animation box-${index}`} style={{ width: '100%', height: '90%' }}>
                          {/* <div className="img-bg-edit" style={{ backgroundImage:`url(${require("./../images/right-bg-edit.jpg")})`, padding: '80px 28px 20px' }}> */}
                            <img className="img-bg-edit"  src={item.img} style={{ width: '100%', height: '100%', marginBottom: '2rem' }} alt="Generic placeholder" />
                          {/* </div> */}
                        </div>
                      </Col>
                    </Media>
                  </div>
                )
              }
            })
          }
        </>
      </Container>
      <Footer></Footer>
    </div>
  )
}

export default ProductPage

