import React from "react"
import "./animate-index.css"
import { Modal } from 'react-bootstrap'

const CommonFlex = (props) => {
  const [ visible, setVisible ] = React.useState(false)

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      let windowHeight = document.documentElement.clientHeight
      window.addEventListener('scroll', (event) => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let box = document.getElementsByClassName('animate__fadeInUp')
        for(let i=0; i<box.length;i++) {
          if(box[i].offsetTop < (windowHeight + scrollTop + 200)) {
            box[i].style.animation = `fadeInUp 500ms linear`
          }else {
            box[i].style.animation = `fadeInDown 500ms linear`
          }
        }
      })
    }
  }, [])

  return (  
    <>
      {
        props?.position == 'left' ?   <div className="common-flex"  name={props.name} style={props.style}>
          <div className="common-box">
            <div className={`animate__fadeInUp ${props.animate}`}>
              <p className="common-title" style={{ marginBottom: '0.5rem' }} dangerouslySetInnerHTML={{__html: props.title}}></p>
              <p className="common-hor-line" style={{ width: props?.product_message ? '17rem' : '9rem', margin: '0 0 0.875rem' }}></p>
              {
                props?.click == 'allow' ? <button className="getprice-btn" style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.2rem 1rem' }} onClick={() => { setVisible(true) }} >
                  <img style={{ width: '2rem', marginRight: '.5rem' }} src={require('./../images/play.png')}/>
                  <div style={{ fontSize: '1.2rem' }} dangerouslySetInnerHTML={{__html: props.desc}}></div>
                </button> :
                <p style={{ maxWidth: '100%' }} className="common-s-title" dangerouslySetInnerHTML={{__html: props.desc}}></p>
              }
            </div>
          </div>
        </div> : <div className="common-flex" name={props.name} style={props.style}>
           <div className="common-box">
            <p className="common-hor-line"></p>
            <div className={`animate__fadeInUp ${props.animate}`}>
              <p className="common-title" dangerouslySetInnerHTML={{__html: props.title}}></p>
              {
                props?.click == 'allow' ? <button className="getprice-btn" style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.2rem 1rem' }} onClick={() => { setVisible(true) }} >
                  <img style={{ width: '2rem', marginRight: '.5rem' }} src={require('./../images/play.png')}/>
                  <div style={{ fontSize: '1.2rem' }} dangerouslySetInnerHTML={{__html: props.desc}}></div>
                </button> :
                <p className="common-s-title" dangerouslySetInnerHTML={{__html: props.desc}}></p>
              }
              
            </div>
          </div>
        </div>
      }
      {
        props?.click == 'allow' ? 
          <Modal className="video-modal-dialog" bsSize="lg" show={visible} onHide={() => setVisible(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <video poster={require('./../images/video-poster.png')} style={{ width: '100%', height: '100%' }}
              src="/strapi/uploads/6_23_1c11da1a26.mp4" controls autoPlay="autoplay" muted={true} loop
            >
            </video>
          </Modal.Body>
        </Modal> : ''
      }
    </>
  )
}

export default CommonFlex
